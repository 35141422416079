import { DEFAULT_SPACING_BETWEEN_ELEMENTS } from '@ui/slice-machine/slices/constants';
import { BlockPadding } from './types';

export const mapBlockPaddingValue = (padding: BlockPadding | null): number => {
  const paddingValues: Record<BlockPadding, number> = {
    none: 0,
    expanded: 15,
    default: DEFAULT_SPACING_BETWEEN_ELEMENTS,
  };

  return paddingValues[padding ?? 'default'];
};
